@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.area {
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.05);
  bottom: -150px;
  animation: rotate 60s linear infinite;
  transform-origin: center center;
}

.circles li:nth-child(1) {
  @apply bg-purple-500/10;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 300px;
  height: 300px;
  animation-delay: 0s;
  @apply rounded-md rotate-12;
  animation-duration: 90s;
}

.circles li:nth-child(2) {
  @apply bg-lime-500/10;
  position: absolute;
  bottom: 10%;
  right: -5%;
  width: 450px;
  height: 450px;
  animation-delay: 0s;
  @apply rounded-3xl -rotate-6;
  animation-duration: 180s;
}

.circles li:nth-child(3) {
  @apply bg-cyan-500/10;
  position: absolute;
  bottom: 20%;
  left: 30%;
  width: 200px;
  height: 200px;
  animation-delay: 0s;
  @apply rounded-3xl -rotate-45;
  animation-duration: 60s;
}

.circles li:nth-child(4) {
  position: absolute;
  right: 5%;
  top: -30%;
  width: 500px;
  height: 500px;
  animation-delay: 0s;
  @apply rounded-3xl -rotate-45;
  animation-duration: 220s;
}
