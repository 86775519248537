.guessing-notification {
  @apply absolute top-1/2 left-2 right-2 text-center;
  animation: animatedText ease 700ms forwards;
}

@keyframes animatedText {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-300%) scale(2);
  }
}
